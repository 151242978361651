// Generated by Framer (9bf39e5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["pb1BsffJ0"];

const variantClassNames = {pb1BsffJ0: "framer-v-f4lyvw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any; image?: {src: string; srcSet?: string}; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "pb1BsffJ0", tap: QxRXhM8TU, image: c4mAnDJ5G, title: vK14iNhCs = "Suzuki hajómotor összeszerelő üzem", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "pb1BsffJ0", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1514nji = activeVariantCallback(async (...args) => {
if (QxRXhM8TU) {
const res = await QxRXhM8TU(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-pkFRJ", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", ...toResponsiveImage(c4mAnDJ5G)}} className={cx("framer-f4lyvw", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"pb1BsffJ0"} onTap={onTap1514nji} ref={ref} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TW9udHNlcnJhdC05MDA=", "--framer-font-family": "\"Montserrat\", serif", "--framer-font-size": "20px", "--framer-font-weight": "900", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Suzuki hajómotor összeszerelő üzem</motion.p></React.Fragment>} className={"framer-z7k2nj"} fonts={["GF;Montserrat-900"]} layoutDependency={layoutDependency} layoutId={"QRvW4O6Ga"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px", textShadow: "0px 1px 9px rgb(0, 0, 0)"}} text={vK14iNhCs} transition={transition} verticalAlignment={"top"} withExternalLayout/></Image>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-pkFRJ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pkFRJ * { box-sizing: border-box; }", ".framer-pkFRJ .framer-1sg8uks { display: block; }", ".framer-pkFRJ .framer-f4lyvw { cursor: pointer; height: 482px; position: relative; width: 856px; }", ".framer-pkFRJ .framer-z7k2nj { bottom: 28px; flex: none; height: auto; left: 35px; position: absolute; white-space: pre-wrap; width: 347px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 482
 * @framerIntrinsicWidth 856
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"QxRXhM8TU":"tap","c4mAnDJ5G":"image","vK14iNhCs":"title"}
 */
const FramerJHGsZTKx4: React.ComponentType<Props> = withCSS(Component, css, "framer-pkFRJ") as typeof Component;
export default FramerJHGsZTKx4;

FramerJHGsZTKx4.displayName = "CMS";

FramerJHGsZTKx4.defaultProps = {height: 482, width: 856};

addPropertyControls(FramerJHGsZTKx4, {QxRXhM8TU: {title: "Tap", type: ControlType.EventHandler}, c4mAnDJ5G: {title: "Image", type: ControlType.ResponsiveImage}, vK14iNhCs: {defaultValue: "Suzuki hajómotor összeszerelő üzem", title: "Title", type: ControlType.String}} as any)

addFonts(FramerJHGsZTKx4, [{family: "Montserrat", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/JHGsZTKx4:default", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-Y3tcoqK5.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-Y3tcoqK5.ttf", weight: "900"}])